<template>
  <div class="more-info-box has-text-grey is-size-7" v-if="product.hasInfoBoxOpen">
    <div class="delete is-clickable" @click="product.hasInfoBoxOpen = false" />
    <div v-if="hasPicture"><img :src="product.otherImages[0].src" v-if="product.otherImages.length > 0" /></div>
    <div v-html="product.shortDescription"/> <div v-if="product.weight != '0'">Gewicht: {{ product.weight }}g</div>
  </div>
</template>

<script>
export default {
  props: {
    product: Object,
    hasPicture: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="css" scoped>
</style>

<template>
  <div class="bike-preview">
    <div class="is-size-2 has-text-dark"><strong>{{ currentModel.name }}</strong> <span class="is-size-6 is-clickable" @click="currentModel.hasInfoBoxOpen = true"><u>Info</u></span></div>

    <ModelInfo
      :has-picture="false"
      :product="currentModel"
    />

    <div class="is-size-5 has-text-grey">{{ currentCategory.name }}</div>
    <div class="wrap" :style="{ width: '100%', height: `${bikePreviewHeight}px` }">
      <div v-if="currentFrameColor && bikePreviewHeight > 0" :style="{ background: `${currentFrameColor.hexCode}`, height: `${bikePreviewHeight - 20}px`, width: '98%', top: '5px', left: '5px' }" class="layer" />
      <img :src="currentModel.imageMask" class="layer" ref="bikePreview" @load="handleResize()" />
      <img :src="currentModel.imageFrame" class="layer" />
      <img :src="currentModel.imageParts" class="layer" />
      <img v-if="hasStvo" :src="currentModel.imageStvo" class="layer" />
      <logo
        :bike-preview-width="bikePreviewWidth"
        :bike-preview-height="bikePreviewHeight"
      />
      <img v-if="visibleHandlebarsImage" :src="visibleHandlebarsImage" class="layer" />
    </div>
    <p class="is-size-7 has-text-grey-light">Die Abbildungen auf dieser Seite stimmen nicht immer 1:1 mit dem produzierten Fahrrad überein. Die dargestellten Farben können nicht so wiedergegeben werden, wie sie in Wirklichkeit sind. Ausstattung kann nicht immer genau im Vorschau-Bild visualisiert werden.</p>
  </div>
</template>

<script>
import { skusIncludingStvo } from '@/variables.js'
import ModelInfo from '@/components/ModelInfo'
import Logo from '@/components/Logo.vue'

export default {
  components: { Logo, ModelInfo },
  data () {
    return {
      referenceImageLoaded: false,
      referenceHeight: 0,
      referenceWidth: 0,
      bikePreviewWidth: 0,
      bikePreviewHeight: 0,
      infoBoxOpen: false
    }
  },
  computed: {
    currentFrameColor () {
      return this.$store.state.currentFrameColor
    },
    hasModelImages () {
      return this.currentModel.imageMask && this.currentModel.imageFrame && this.currentModel.imageParts
    },
    hasStvo () {
      if (this.configuration.components.accessoires.stvoBasic) {
        return skusIncludingStvo.includes(this.configuration.components.accessoires.stvoBasic.sku)
      }
      return false
    },
    configuration () {
      return this.$store.getters.getCurrentConfiguration
    },
    visibleHandlebarsImage () {
      let imageSrc = null
      const handlebars = this.configuration.components.options.handlebars
      // if custom image is included in handlebars, check if it should be shown for this model
      if (handlebars && handlebars.otherImages) {
        handlebars.otherImages.every(image => {
          let showImageFor = image.alt.split(',')
          if (showImageFor.includes(this.currentModel.sku)) {
            imageSrc = image.src
            // break out if found
            return false
          }
          return true
        })
      }
      return imageSrc
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize)
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize)
  },
  methods: {
    // check the size of the reference image which is important for logo position and the frame color
    handleResize () {
      this.bikePreviewWidth = this.$refs.bikePreview.clientWidth
      this.bikePreviewHeight = this.$refs.bikePreview.clientHeight
    },
    checkLoad () {
      this.referenceImageLoaded = true
      this.setReferenceHeight()
      this.setReferenceWidth()
    },
    setReferenceHeight () {
      if (this.$refs.referenceImage) { this.referenceHeight = this.$refs.referenceImage.clientHeight }
    },
    setReferenceWidth () {
      console.log(this.$refs.referenceImage.clientWidth)
      if (this.$refs.referenceImage) { this.referenceWidth = this.$refs.referenceImage.clientWidth }
    },
  },
  watch: {
    currentModel () {
      this.setReferenceHeight()
    }
  }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/utilities/initial-variables.sass"
.bike-preview
  position: relative
  background: $white-ter
  padding: $base-gap $base-gap * 2
  .wrap
    z-index: 90
    position: relative
    .layer
      width: 100%
      position: absolute
</style>